import { Injectable, OnDestroy } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { takeUntil, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";

import { Settings, Response } from 'app/core/interfaces/all.interface';
import { ServerWebPipe } from 'app/pipes/server_web.pipe';

@Injectable()
export class SettingsService implements OnDestroy {
  
  private _settings: Settings = {} as Settings;
  private _subject: Subject<Settings> = new Subject();

  private _unsubscribeAll: Subject<any | null>;

  constructor(private http: HttpClient) {
    this._unsubscribeAll = new Subject();
  }

  all(): Observable<Settings> {
    this.http.get<Response<any[]>>(new ServerWebPipe().transform(`settings`)).pipe(takeUntil(this._unsubscribeAll)).subscribe((response) => {
      for(var p in response.data) {
        let _name = (response.data[p].name || '').toLowerCase();
        this._settings[_name] = response.data[p].value;
      }

      this._subject.next(this._settings);
    });

    return this._subject;
  }

  getCurrentSettings(): Settings {
    return this._settings;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
