import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

import { Login, User, Response, Active, Address, ConektaCard, ConektaCardSource, RFCs as InvoiceData } from 'app/core/interfaces/all.interface';

import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import { ServerFilePipe } from 'app/pipes/server_file.pipe';


@Injectable()
export class UsersService {
  userReady: Subject<User> = new Subject<User>();

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<User>> {
    return id > 0 ? this.http.get<Response<User>>(new ServerApiPipe().transform(`users/${id}`)).pipe(takeUntil(this.userReady)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        title: '',
        user_group_id: 0,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        tel_oficina: '',
        profesion: '',
        bday: null,
        gender: '',
        rfc: '',
        no_licencia: '',
        cedula_profesional: '',
        matricula_profesional: '',
        especialidad: '',
        biography: '',
        last_login: null
      } as User
    } as Response<User>);
  }
  
  all(body: any): Observable<Response<User[]>> {
    let params = new HttpParams().set("s", body.s);

    return this.http.get<Response<User[]>>(new ServerApiPipe().transform(`users/`), { params });
  }

  activate(user: User, active: boolean = true): Observable<Response<Active>> {
    return this.http.put<Response<Active>>(new ServerApiPipe().transform(`users/${user.id}/activate`), { active: active });
  }

  delete(user: User): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`users/${user.id}`));
  }

  update(user: User): Observable<Response<User>> {
    return this.http.put<Response<User>>(new ServerApiPipe().transform(`users/${user.id}`), user);
  }

  save(user: User): Observable<Response<User>> {
    return this.http.post<Response<User>>(new ServerApiPipe().transform(`users/`), user);
  }

  getAvatar(user: User): Observable<Blob> {
    return this.http.get(new ServerApiPipe().transform(`users/${user.id}/avatar`), { responseType: 'blob' });
  }

  setAvatar(user: User, avatar: File): Observable<Response<any>> { 
    const formData: FormData = new FormData();
    formData.append('avatar', avatar, avatar.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<any>>(new ServerApiPipe().transform(`users/${user.id}/avatar`), formData, { headers });
  }

  updateAddress(user: User, address: Address): Observable<Response<Address>> {
    return this.http.put<Response<Address>>(new ServerApiPipe().transform(`users/${user.id}/address/${address.id}`), address);
  }

  saveAddress(user: User, address: Address):Observable<Response<Address>> {
    return this.http.post<Response<Address>>(new ServerApiPipe().transform(`users/${user.id}/address/`), address);
  }

  deleteAddress(user: User, address: Address): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`users/${user.id}/address/${address.id}`));
  }

  getAddress(user: User, address: Address): Observable<Response<Address>> {
    return this.http.get<Response<Address>>(new ServerApiPipe().transform(`users/${user.id}/address/${address.id}`));
  }

  setCard(user: User, card: ConektaCard): Observable<Response<ConektaCardSource>> {
    return this.http.post<Response<ConektaCardSource>>(new ServerApiPipe().transform(`users/${user.id}/card`), card);
  }

  deleteCard(user: User, card: ConektaCardSource): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`users/${user.id}/card/${card.id}`));
  }

  addInvoiceData(user: User, invoice: InvoiceData): Observable<Response<InvoiceData>> {
    return this.http.post<Response<InvoiceData>>(new ServerApiPipe().transform(`users/${user.id}/rfcs`), invoice);
  }

  deleteInvoiceData(user: User, invoice: InvoiceData): Observable<Response<null>> {
    return this.http.delete<Response<null>>(new ServerApiPipe().transform(`users/${user.id}/rfcs/${invoice.id}`));
  }
}
