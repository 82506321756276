
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Category, User, Response, Active } from 'app/core/interfaces/all.interface';
import { ServerWebPipe } from 'app/pipes/server_web.pipe';

@Injectable()
export class CategoriesService {

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<Category>> {
    return  id > 0 ? this.http.get<Response<Category>>(new ServerWebPipe().transform(`categories/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        name: '',
        description: '',
        color: '#FFFFFF',
        active: false
      } as Category
    } as Response<Category>);
  }

  all(body: any = {}): Observable<Response<Category[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Category[]>>(new ServerWebPipe().transform(`categories`), { params });
  }

  availableOnline(category: Category, active: boolean = true): Observable<Response<Category>> {
    return this.http.put<Response<Category>>(new ServerWebPipe().transform(`categories/${category.id}`), category);
  }

  delete(category: Category): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerWebPipe().transform(`categories/${category.id}`));
  }

  update(category: Category): Observable<Response<Category>> {
    return this.http.put<Response<Category>>(new ServerWebPipe().transform(`categories/${category.id}`), category);
  }

  save(category: Category): Observable<Response<Category>> {
    return this.http.post<Response<Category>>(new ServerWebPipe().transform(`categories/`), category);
  }
}
