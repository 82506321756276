import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ 
  name: 'dateFormat',
  pure: false
})
export class DateFormatPipe implements PipeTransform {
    transform(value: Date | moment.Moment, dateFormat: string, fromFormat?: string): any {
        return moment(value, fromFormat || null).format(dateFormat);
    }
}