import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ 
  name: 'oxxoTime',
  pure: false
})
export class OxxoTimePipe implements PipeTransform {
  transform(value: string, variable?: string): any {
    let _json = JSON.parse(value);
    return moment.unix(_json[variable || 'expires_at'] || '').format('DD MMM YYYY hh:mm a');
  }
}