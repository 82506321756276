import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Response, Active, CouponResponse } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class CouponsService {
  public _coupon: CouponResponse;


  get Coupon(): any {
    return this._coupon;
  }

  constructor(private http: HttpClient) {}

  validate(code: string | number): Observable<Response<CouponResponse>> {
    return this.http.get<Response<CouponResponse>>(new ServerApiPipe().transform(`coupons/validate/${code}`));
  }

  setCoupon(coupon: CouponResponse) {
    this._coupon = coupon;
  }
}
