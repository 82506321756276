
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Product, User, Response, Active, Catalog } from 'app/core/interfaces/all.interface';

import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import { ServerWebPipe } from 'app/pipes/server_web.pipe';

import * as _ from 'lodash';

@Injectable()
export class ProductsService {

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<Product>> {
    return  id > 0 ? this.http.get<Response<Product>>(new ServerApiPipe().transform(`products/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        name: '',
        description: '',
        short_description: '',
        product_type_id: 1,
        precio_real: 0,
        precio_venta: 0,
        precio_promocion: 0,
        product_category_id: 0,
        product_application_type_id: 0,
        dispatchable: false,
        in_office: false,
        tags: '',
        active: false,
        requirements: []
      } as Product
    } as Response<Product>);
  }

  all(body: any = {}): Observable<Response<Product[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Product[]>>(new ServerApiPipe().transform(`products`), { params });
  }

  delete(product: Product): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`products/${product.id}`));
  }

  update(product: Product): Observable<Response<Product>> {
    return this.http.put<Response<Product>>(new ServerApiPipe().transform(`products/${product.id}`), product);
  }

  save(product: Product): Observable<Response<Product>> {
    return this.http.post<Response<Product>>(new ServerApiPipe().transform(`products/`), product);
  }

  cart(products: any): Observable<Response<{ products: any[], offices: any[]}>> {
    let _offices: any[] = [];
    let _products: any[] = [];

    products.forEach(p => {
      _offices.push(p.office_id);
      _products.push(`${p.product_id}:${p.office_id}`);
    });
    return this.http.post<Response<{ products: any[], offices: any[]}>>(new ServerWebPipe().transform(`products/cart`), { ids: _products, offices: _.uniq(_offices) });
  }
}
