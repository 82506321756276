
import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Appointment, Response } from 'app/core/interfaces/all.interface';
import { ServerWebPipe } from 'app/pipes/server_web.pipe';
import * as moment from 'moment';

@Injectable()
export class AppointmentsService {
  constructor(private http: HttpClient) {}

  range(week: moment.Moment[], service: any, product: any): Observable<Response<string[]>> {
    const [start] = week;
    const end = week.pop();

    return this.http.post<Response<string[]>>(new ServerWebPipe().transform(`appointments/available/range`), {
      start_date: start.hour(0).minute(0).seconds(0).format('YYYY-MM-DD HH:mm:ss'),
      end_date: end.hour(23).minute(59).seconds(0).format('YYYY-MM-DD HH:mm:ss'),
      office: service.office.id,
      product: product.id
    });
  }
}
