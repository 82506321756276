import { Pipe, PipeTransform } from '@angular/core';

import { environment as env } from 'environments/environment';

@Pipe({
  name: 'server_web'
})
export class ServerWebPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return `${env.web.url}${value}`;
  }
}
