import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Response, Active, PaymentMethod } from 'app/core/interfaces/all.interface';
import { ServerWebPipe } from 'app/pipes/server_web.pipe';

@Injectable()
export class PaymentMethodsService {

  constructor(private http: HttpClient) {}

  list(): Observable<Response<PaymentMethod[]>> {
    return this.http.get<Response<PaymentMethod[]>>(new ServerWebPipe().transform(`paymentmethods/list`));
  }
}
