import { Settings } from '../interfaces/all.interface';

const PayPal = {
  init: (settings: Settings = {} as Settings) => {
    var paypal_script = document.createElement("script"), head = document.head || document.getElementsByTagName("head")[0];
    paypal_script.onload = function () {
      
    };
    paypal_script.src = `https://www.paypal.com/sdk/js?client-id=${settings.paypal_client}&currency=${settings.currency || 'USD'}&disable-funding=credit,card`;
    paypal_script.async = true;

    head.insertBefore(paypal_script, head.firstChild);
  }
}


export default PayPal;