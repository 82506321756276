/**
 * Created by xavi on 5/16/17.
 */
import {Injectable} from "@angular/core";
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { CookieService } from 'ngx-cookie';

import { Session, User, Office, SessionOffice } from "app/core/interfaces/all.interface";

import { environment as env } from 'environments/environment';
import * as moment from 'moment';

@Injectable()
export class StorageService {
  private currentSession : Session = null;
  private currentUser: User;

  private _onCurrentUserChanged: BehaviorSubject<User>;

  constructor(private router: Router, private _cookies: CookieService) {
    this.currentSession = this.loadSessionData();
    this._onCurrentUserChanged = new BehaviorSubject(null);
  }

  setCurrentSession(session: Session): Observable<Session> {
    this.currentSession = session;
    this._cookies.put(env.local.user, JSON.stringify(session), { expires: new Date(session.expiration_date) });

    return of(this.currentSession);
  }

  setCurrentUser(user: User): void {
    this.currentUser = user;
    this._onCurrentUserChanged.next(user);
  }

  loadSessionData(): Session {
    var sessionStr = this._cookies.get(env.local.user);
    return (sessionStr) ? <Session> JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): Session {
    return this.loadSessionData();
  }

  getCurrentUser(): User {
    return this.currentUser;
  }

  removeCurrentSession(): void {
    this._cookies.remove(env.local.user);
    this.currentSession = null;
    this.currentUser = null;
  }

  removeCurrentUser(): void {
    this.removeCurrentSession();
  }

  getCurrentUserId(): Number | null {
    var session: Session = this.getCurrentSession();
    return (session && session.user_id);
  };

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null);
  };

  getCurrentToken(): String | null {
    var session: Session = this.getCurrentSession();
    return (session && session.token_key);
  };

  logout(): void {
    this.removeCurrentSession();
    // this.router.navigate(['/']);
  }

  onCurrentUserChanged(): Observable<User|null> {
    return this._onCurrentUserChanged.asObservable();
  }

  setCurrentOffice(office: Office): void {
    this._cookies.put('web.office', JSON.stringify(office), { expires: moment().add({minute: 30 }).toDate() });
  }

  getCurrentOffice(): Office {
    return this._cookies.get('web.office') ? <Office> JSON.parse(this._cookies.get('web.office')) : null;
  }

  storageLanguege(language: string): string | null {
    this._cookies.put('s_lang', language);
    return language;
  }

  getLanguage(): string | null {
    return this._cookies.get('s_lang') || '';
  }
}
