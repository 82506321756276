
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Login, User as Client, Response, Active, Address } from 'app/core/interfaces/all.interface';

import { ServerApiPipe } from 'app/pipes/server_api.pipe';

@Injectable()
export class ClientsService {

  constructor(private http: HttpClient) {}

  all(body: any): Observable<Response<Client[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Client[]>>(new ServerApiPipe().transform(`clients/`), { params });
  }

  get(id: Number): Observable<Response<Client>> {
    return id > 0 ? this.http.get<Response<Client>>(new ServerApiPipe().transform(`users/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        title: '',
        user_group_id: 0,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        tel_oficina: '',
        profesion: '',
        bday: null,
        gender: '',
        rfc: '',
        no_licencia: '',
        cedula_profesional: '',
        matricula_profesional: '',
        especialidad: '',
        biography: '',
        last_login: null
      } as Client
    } as Response<Client>);
  }

  activate(client: Client, active: boolean = true): Observable<Response<Active>> {
    return this.http.put<Response<Active>>(new ServerApiPipe().transform(`users/${client.id}/activate`), { active: active });
  }

  delete(client: Client): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`users/${client.id}`));
  }

  update(client: Client): Observable<Response<Client>> {
    return this.http.put<Response<Client>>(new ServerApiPipe().transform(`users/${client.id}`), client);
  }

  save(client: Client): Observable<Response<Client>> {
    return this.http.post<Response<Client>>(new ServerApiPipe().transform(`users/`), client);
  }

  getAvatar(client: Client): Observable<Blob> {
    return this.http.get(new ServerApiPipe().transform(`users/${client.id}/avatar`), { responseType: 'blob' });
  }

  setAvatar(client: Client, avatar: File): Observable<Response<any>> { 
    const formData: FormData = new FormData();
    formData.append('avatar', avatar, avatar.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<any>>(new ServerApiPipe().transform(`users/${client.id}/avatar`), formData, { headers });
  }

  updateAddress(client: Client, address: Address): Observable<Response<Address>> {
    return this.http.put<Response<Address>>(new ServerApiPipe().transform(`users/${client.id}/address/${address.id}`), address);
  }

  saveAddress(client: Client, address: Address):Observable<Response<Address>> {
    return this.http.post<Response<Address>>(new ServerApiPipe().transform(`users/${client.id}/address/`), address);
  }

  deleteAddress(client: Client, address: Address): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`users/${client.id}/address/${address.id}`));
  }

  getAddress(client: Client, address: Address): Observable<Response<Address>> {
    return this.http.get<Response<Address>>(new ServerApiPipe().transform(`users/${client.id}/address/${address.id}`));
  }
}
