import { NgModule } from '@angular/core';

import { PhonePipe } from './phone.pipe';
import { ServerFilePipe } from './server_file.pipe';
import { ServerApiPipe } from './server_api.pipe';
import { DistancePipe } from './distance.pipe';
import { DateFormatPipe } from './dateFormat.pipe';
import { OxxoReferencePipe } from './oxxoReference.pipe';
import { OxxoTimePipe } from './OxxoRemainTime.pipe';
import { IntegrationApiPipe } from './integration_api.pipe';

@NgModule({
  declarations: [
    PhonePipe,
    ServerFilePipe,
    ServerApiPipe,
    DistancePipe,
    DateFormatPipe,
    OxxoReferencePipe,
    OxxoTimePipe,
    IntegrationApiPipe
  ],
  imports     : [],
  exports     : [
    PhonePipe,
    ServerFilePipe,
    ServerApiPipe,
    DistancePipe,
    DateFormatPipe,
    OxxoReferencePipe,
    OxxoTimePipe,
    IntegrationApiPipe
  ]
})
export class PipesModule {}
