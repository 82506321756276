import { Pipe, PipeTransform } from '@angular/core';

import { environment as env } from 'environments/environment';

@Pipe({
  name: 'integration_api'
})
export class IntegrationApiPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return `${env.api.integration.url}${value}`;
  }
}
