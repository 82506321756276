
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Response, OrderProduct, Order } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';
import { ServerWebPipe } from 'app/pipes/server_web.pipe';


@Injectable({ providedIn: 'root' })
export class OrdersService {
  constructor(private http: HttpClient) {}

  get(uuid: string): Observable<Response<Order>> {
    return this.http.get<Response<Order>>(new ServerWebPipe().transform(`orders/${uuid}`));
  }

  all(body: any = {}): Observable<Response<Order[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Order[]>>(new ServerApiPipe().transform(`orders`), { params });
  }

  delete(order: Order): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`orders/${order.id}`));
  }

  update(order: Order): Observable<Response<Order>> {
    return this.http.put<Response<Order>>(new ServerApiPipe().transform(`orders/${order.id}`), order);
  }

  save(order: Order): Observable<Response<Order>> {
    return this.http.post<Response<Order>>(new ServerApiPipe().transform(`orders`), order);
  }
}
