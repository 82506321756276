
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageService } from "app/core/services/storage.service";

import { OfficesService } from 'app/core/services/offices.service';

import { Response, Office } from 'app/core/interfaces/all.interface';

@Injectable()
export class OfficeGuard implements CanActivate {

  constructor(
    private router: Router, 
    private _storage: StorageService, 
    private _offices: OfficesService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, next: RouterStateSnapshot) {
    const _office: Office = this._offices.getCurrentOffice();

    if (!_office && route.data.validateOffice) {
      this.router.navigate([ '/' ]);
      return false;
    } else {
      return true;
    }
  }

}
