/**
 * Created by xavi on 5/16/17.
 */
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Role, User, Response, Active } from 'app/core/interfaces/all.interface';
import { ServerApiPipe } from 'app/pipes/server_api.pipe';


@Injectable()
export class RolesService {

  constructor(private http: HttpClient) {}

  get(id: Number): Observable<Response<Role>> {
    return  id > 0 ? this.http.get<Response<Role>>(new ServerApiPipe().transform(`roles/${id}`)) : of({
      status: 'success',
      success: true,
      message: '',
      data: {
        name: '',
        description: '',
        available_online: false,
      } as Role
    } as Response<Role>);
  }

  all(body: any = {}): Observable<Response<Role[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Role[]>>(new ServerApiPipe().transform(`roles`), { params });
  }

  availableOnline(role: Role, available_online: boolean = true): Observable<Response<Role>> {
    return this.http.put<Response<Role>>(new ServerApiPipe().transform(`roles/${role.id}`), role);
  }

  delete(role: Role): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerApiPipe().transform(`roles/${role.id}`));
  }

  update(role: Role): Observable<Response<Role>> {
    return this.http.put<Response<Role>>(new ServerApiPipe().transform(`roles/${role.id}`), role);
  }

  save(role: Role): Observable<Response<Role>> {
    return this.http.post<Response<Role>>(new ServerApiPipe().transform(`roles/`), role);
  }
}
