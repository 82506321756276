import { Component, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil, delay } from 'rxjs/operators';

import Conekta from 'app/core/helper/conekta.helper';
import PayPal from 'app/core/helper/paypal.helper';

import { Settings } from 'app/core/interfaces/all.interface';
import { SettingsService } from 'app/core/services/settings.service';
import { ServerFilePipe } from 'app/pipes/server_file.pipe';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {

  private _unsubscribeAll: Subject<any | null>;
  
  constructor(private _settings: SettingsService) {
    this._unsubscribeAll = new Subject();
    moment.locale('es-mx');
    this._settings.all().pipe(takeUntil(this._unsubscribeAll)).subscribe((settings: Settings) => {
      if (settings.conekta_client) Conekta.initConekta(settings);
      if (settings.paypal_client) PayPal.init(settings);

      if (settings.company_name) {
        document.title = settings.company_name;
      }
    });

    let _favicon = document.createElement('link');
    _favicon.rel = 'icon';
    _favicon.type = 'image/png';
    _favicon.href = new ServerFilePipe().transform('/storage/company/company_icon.png');

    document.head.appendChild(_favicon);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
