import { Injectable } from '@angular/core';
import { StorageService } from 'app/core/services/storage.service';
import { map, filter } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { User } from 'app/core/interfaces/all.interface';

import * as htmlToImage from 'html-to-image';
import * as _ from 'lodash';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public _cart: any[];

  private _onCartChanged: BehaviorSubject<any>;

  get Items(): number {
    return this._cart ? this._cart.length : 0;
  }

  get Cart(): any {
    return this._cart;
  }

  constructor(
    private _storage: StorageService
  ) {
    if (localStorage.getItem('store.cart')) {
      this._cart = JSON.parse(localStorage.getItem('store.cart'));
      this._cart = this._cart.map(c => {
        return { ...c, ...{ descuento: 0, coupon: null } };
      });
    } else {
      this._cart = [];
    }
    this._onCartChanged = new BehaviorSubject(null);
  }

  private _spinnerName = 'fullpage';

  dataURItoBlob(dataURI, type: string = 'image/png') {
    const byteString = window.atob(dataURI.replace(`data:${type};base64,`, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });    
    return blob;
  }

  ToFile(name: string = 'name.png', base64: string = '', type: string = 'image/png') {
    const imageName = 'name.png';
    const imageBlob = this.dataURItoBlob(base64, type);
    const imageFile = new File([imageBlob], imageName, { type });

    return imageFile;
  }

  hasAccess(access: string): boolean {
    let _user = this._storage.getCurrentUser();

    return access.toLowerCase() === 'guest' || (_user.permisos.filter(p => p.access).map(p => `${p.controller}/${p.action}`).find(p => p === access) ? true : false);
  }

  getPosition(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this._storage.getCurrentOffice()) {
        resolve({ message: true, code: 1 });
      } else {
        navigator.geolocation.getCurrentPosition(resp => {
          resolve(resp);
        }, err => {
          resolve(err);
        });
      }
    });
  }

  calculateDistance(lat1, lon1, lat2, lon2, unit): number {
    var radlat1 = Math.PI * lat1/180;
    var radlat2 = Math.PI * lat2/180;
    var radlon1 = Math.PI * lon1/180;
    var radlon2 = Math.PI * lon2/180;
    var theta = lon1-lon2;
    var radtheta = Math.PI * theta/180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit=="K") { dist = dist * 1.609344 };
    if (unit=="N") { dist = dist * 0.8684 };
    return dist;
  }

  addToFav(officeProduct) {
    if (this._storage.isAuthenticated()) {

    } else {
      let _favs = JSON.parse(localStorage.getItem('products.favs') || '[]');

      if (_favs.includes(officeProduct.product.id)) {
        _.remove(_favs, p => p == officeProduct.product.id);
      } else {
        _favs.push(officeProduct.product.id);
      }

      localStorage.setItem('products.favs', JSON.stringify(_favs));      
    }
  }

  addToCart(cart_item: any, update: boolean = true) {
    let _found = this._cart.findIndex(f => f.product_id === cart_item.product_id && f.office_id == cart_item.office_id);

    if (_found > -1) {
      this._cart[_found] = cart_item;
    } else {
      this._cart.push(cart_item);
    }
    localStorage.setItem('store.cart', JSON.stringify(this._cart));
    if (update)
      this._onCartChanged.next(this._cart);
  }

  clearCart() {
    _.remove(this._cart, ()=>true);
    localStorage.setItem('store.cart', JSON.stringify(this._cart));
    this._onCartChanged.next(this._cart);
  }

  removeFromCart(cart_item: any, update: boolean = true) {
    let _found = this._cart.findIndex(f => f.product_id === cart_item.product_id && f.office_id == cart_item.office_id);

    if (_found > -1) {
      this._cart.splice(_found, 1);
    }
    localStorage.setItem('store.cart', JSON.stringify(this._cart));
    if (update)
      this._onCartChanged.next(this._cart);
  }

  findInCart(cart_item: any) {
    let _found = this._cart.find(f => f.product_id === cart_item.product_id && f.office_id == cart_item.office_id);
    return _found;
  }

  removeService(cart_item: any, service: any) {
    let _found = this._cart.findIndex(f => f.product_id === cart_item.product_id);

    if (_found > -1) {
      if (this._cart[_found].servicios.length == 1) {
        this.removeFromCart(cart_item);
      } else {
        this._cart[_found].servicios.splice(service, 1);
      }
    }

    localStorage.setItem('store.cart', JSON.stringify(this._cart));
    this._onCartChanged.next(this._cart);
  }

  updateCart() {
    localStorage.setItem('store.cart', JSON.stringify(this._cart));
    this._onCartChanged.next(this._cart);
  }

  download(blob: Blob, name: string) {
    var downloadURL = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
  }

  toBlob(element, name): Promise<any> {
    return new Promise((resolve, reject) => {
      htmlToImage.toBlob(element).then((blob) => {
        this.download(blob, `${name}.png`);
        resolve(blob);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  onCartChanged(): Observable<any> {
    return this._onCartChanged.asObservable();
  }
}
