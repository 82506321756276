import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
  name: 'oxxoReference',
  pure: false
})
export class OxxoReferencePipe implements PipeTransform {
  transform(value: string, variable?: string): any {
    let _json = JSON.parse(value);
    return (_json[variable || 'reference'] || '').replace(/^(\d{4})(\d{4})(\d{4})(\d{2})/g, '$1-$2-$3-$4');
  }
}