
import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

import { Login, Office, Response, Active, Address, OfficeProduct, Catalog } from 'app/core/interfaces/all.interface';
import { StorageService } from 'app/core/services/storage.service';

import { ServerWebPipe } from 'app/pipes/server_web.pipe';

@Injectable()
export class OfficesService {
  private _onCurrentOfficeChanged: BehaviorSubject<Office | boolean>;
  _currentOffice: Office;

  get currentOffice() {
    return this._currentOffice || this._storage.getCurrentOffice();
  }

  constructor(
    private http: HttpClient,
    private _storage: StorageService
  ) {
    this._onCurrentOfficeChanged = new BehaviorSubject(false);
    if (this.currentOffice) {
      this.setCurrentOffice(this.currentOffice);
    } else {
    }
  }

  all(body: any): Observable<Response<Office[]>> {
    let params = new HttpParams().set("s", body.s || '');

    return this.http.get<Response<Office[]>>(new ServerWebPipe().transform(`sucursales`));
  }

  get(id: Number, body: any = {}): Observable<Response<Office>> {
    return this.http.post<Response<Office>>(new ServerWebPipe().transform(`sucursales/get/${id}`), body);
  }

  activate(office: Office, active: boolean = true): Observable<Response<Active>> {
    return this.http.put<Response<Active>>(new ServerWebPipe().transform(`sucursales/${office.id}/activate`), { active: active });
  }

  delete(office: Office): Observable<Response<any>> {
    return this.http.delete<Response<any>>(new ServerWebPipe().transform(`sucursales/${office.id}`));
  }

  update(office: Office): Observable<Response<Office>> {
    return this.http.put<Response<Office>>(new ServerWebPipe().transform(`sucursales/${office.id}`), office);
  }

  save(office: Office): Observable<Response<Office>> {
    return this.http.post<Response<Office>>(new ServerWebPipe().transform(`sucursales/`), office);
  }

  getLogotipo(office: Office): Observable<Blob> {
    return this.http.get(new ServerWebPipe().transform(`sucursales/${office.id}/logotipo`), { responseType: 'blob' });
  }

  getProduct(slug: string, office_id?: string): Observable<Response<Catalog>> {
    return this.http.get<Response<Catalog>>(new ServerWebPipe().transform(`sucursales/${office_id || this.currentOffice.id}/products/${slug}`));
  }

  setLogotipo(office: Office, logotipo: File): Observable<Response<any>> { 
    const formData: FormData = new FormData();
    formData.append('logotipo', logotipo, logotipo.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.http.post<Response<any>>(new ServerWebPipe().transform(`sucursales/${office.id}/logotipo`), formData, { headers });
  }

  async setCurrentOffice(office: Office, filter: any = {}) {
    if (office) {
      let response = await this.get(office.id, filter).toPromise();

      this._currentOffice = response.data;
      this._storage.setCurrentOffice(office);

      this._onCurrentOfficeChanged.next(response.data);

      return response;
    } else {
      this._storage.setCurrentOffice(null);

      this._currentOffice = null;
      this._onCurrentOfficeChanged.next(null);
    }
    return of(null);
  }

  getCurrentOffice(): Office {
    return this._currentOffice;
  }

  onCurrentOfficeChanged(): Observable<Office | boolean> {
    return this._onCurrentOfficeChanged.asObservable();
  }
}
